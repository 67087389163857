html, body, div#root {
  height: 100%;
}

/* set a max-width for horizontal fluid layout and make it centered */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px; /* or 950px */
}

#appui {
  height: 100%;
  width: 100%;
}

#header {
  position: sticky;
  top: 0;
  z-index: 999;
}

#appFooter {
  height: 30px;
  position: fixed;
  bottom: 0;
}

.dashboardSideBox {
  min-height: 300px;
  max-height: 600px;
  overflow: auto;
}

.dashboardSideBox-sm {
  min-height: 100px;
  max-height: 250px;
  overflow: auto;
}

.dashboardSideBox-md {
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
}

.App {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.nav-item.active {
}

.nav-tabs > a {
  color: #97A238;
}

.page-header {
  text-align: center;
  background-color: #4286f4;
  color: #fff;
  font-size: 18pt;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}


.hidden {
  display: none;
}

.text-light {
  color: #cccccc;
}

.card-header-tabs {
  border-bottom: 1px solid rgb(222, 226, 230);
}

.navbar .nav-item {
  color: rgb(94, 94, 170);
  cursor: pointer;
}

.navbar .nav-item.active {
  color: rgb(33, 37, 41);
  /*font-weight: bold;*/
  border-bottom: 2px #BACC00 solid;
}

a#nav-user-dropdown.dropdown-toggle.nav-link::after {
  /* Changing caret in user profile dropdown to match text of dropdown */
  border-top-color: #ccc;
  border-bottom-color: #ccc;
}

.tab-content {
  padding-top: 1.5rem;
}

table.table {
  font-size: 0.8rem;
}

.form-row {
  margin-left: -2px;
  margin-right: -2px;
}

.form-row label {
  margin-bottom: 0;
  font-size: 0.75rem;
}

.form-row .form-group {
  margin-bottom: 0.5rem;
}

.lookupTableEditor {
  max-height: 500px;
  overflow-y: auto;
}

.dropdown-menu.show {
  z-index: 999;
}

.font-medium {
  font-size: 1.0em;
}

.form-check.fullSizeLabel > label.form-check-label {
  font-size: 1rem;
}

.border-purple {
  border-color: #663399;
}

.text-purple {
  color: #663399;
}

.work-task-board .card-body {
  padding: 0.75rem;
}

.task-stats-cell {
  text-align: center;
}

.tiny-hint {
  font-size: 0.7rem;
  color: gray;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.parent-hover-parent .parent-hover-child {
  visibility: hidden;
}

.parent-hover-parent:hover .parent-hover-child {
  visibility: visible;
}

@media print {
  .no-print {
    display:none;
  }
}
@media screen {
  .print-only {
    display: none;
  }
}